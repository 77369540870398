import * as React from "react"
import tw, { styled } from "twin.macro"

const Container = styled.div`
    ${tw`bg-gradient-to-r from-blue-800 to-blue-600`}
`

const InnerContainer = styled.div`
    ${tw`max-w-screen-xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8 lg:py-20`}
`

const HeaderContainer = styled.div`
    ${tw`max-w-4xl mx-auto text-center`}
`

const Header = styled.h2`
    ${tw`text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10`}
`

// const HeaderText = styled.p`
//     ${tw`mt-3 text-xl leading-7 text-blue-200 sm:mt-4`}
// `

const BlockContainer = styled.dl`
    ${tw`mt-10 text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8`}
`

const Block = styled.div`
    ${tw`flex flex-col`}
`

const NonFirstBlock = styled(Block)`
    ${tw`mt-10 sm:mt-0`}
`

const BlockLabel = styled.dt`
    ${tw`order-2 mt-2 text-lg leading-6 font-medium text-blue-200`}
`

const BlockValue = styled.dd`
    ${tw`order-1 text-5xl leading-none font-extrabold text-white`}
`

const PromoData = () => {
  return (
    <Container>
      <InnerContainer>
        <HeaderContainer>
          <Header>
          Tysiące zadowolonych absolwentów potwiedzają jakość naszych szkoleń
          </Header>
        </HeaderContainer>
        <BlockContainer>
          <Block>
            <BlockLabel>
              Działalności
            </BlockLabel>
            <BlockValue>
              12 lat
            </BlockValue>
          </Block>
          <NonFirstBlock>
            <BlockLabel>
              Absolwentów 
            </BlockLabel>
            <BlockValue>
              1000
            </BlockValue>
          </NonFirstBlock>
          <NonFirstBlock>
            <BlockLabel>
              Przeprowadzonych kursów
            </BlockLabel>
            <BlockValue>
              140
            </BlockValue>
          </NonFirstBlock>
        </BlockContainer>
      </InnerContainer>
    </Container>
  )
}

export default PromoData
