import React from "react"
import tw, { styled } from "twin.macro"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Container = styled.div`
  ${tw`bg-white`}
`

const InnerContainer = styled.div`
  ${tw`mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-24`}
`

const InnerContainerWrapper = styled.div`
  ${tw`grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8`}
`

const SectionDescription = styled.div`
  ${tw`space-y-5 sm:space-y-4`}
`

const TeamMembersWrapper = styled.div`
  ${tw`lg:col-span-2`}
`

const TeamMembersList = styled.ul`
  ${tw`space-y-12 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:gap-x-8`}
`

const TeamMemberListItemWrapper = styled.div`
  ${tw`flex items-center space-x-4 lg:space-x-6`}
`

const Header = styled.h2`
  ${tw`text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl`}
`

const Description = styled.p`
  ${tw`text-xl leading-7 text-gray-500`}
`

const Image = styled(Img)`
  ${tw`w-16 h-16 rounded-full lg:w-20 lg:h-20`}
`

const TeamMemberMeta = styled.div`
  ${tw`font-medium text-lg leading-6 space-y-1`}
`

const TeamMemberJob = styled.p`
  ${tw`text-blue-700`}
`

const Team = () => {
  const data = useStaticQuery(graphql`
    query {
      bozena: file(relativePath: { eq: "team/bozena-krasnodebska-www.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      anita: file(relativePath: { eq: "team/anita.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ewa: file(relativePath: { eq: "team/ewa.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lukasz: file(relativePath: { eq: "team/lukasz2.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      maria: file(relativePath: { eq: "team/maria.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Container>
      <InnerContainer>
        <InnerContainerWrapper>
          <SectionDescription>
            <Header>Poznaj nasz zespół</Header>
            <Description>
              Nasi wykładowcy to doskonali nauczyciele. Posiadają wykształcenie
              ekonomiczne, lata doświadczeń w zawodzie oraz umiejętność
              przekazania wiedzy w przystępny sposób, dodatkowo przytaczając
              przykłady z własnego życia zawodowego.
            </Description>
          </SectionDescription>
          <TeamMembersWrapper>
            <TeamMembersList>
              <li>
                <TeamMemberListItemWrapper>
                  <Image fluid={data.bozena.childImageSharp.fluid} alt="" />
                  <TeamMemberMeta>
                    <h4>Bożena Krasnodębska</h4>
                    <TeamMemberJob>księgowość</TeamMemberJob>
                  </TeamMemberMeta>
                </TeamMemberListItemWrapper>
              </li>

            </TeamMembersList>
          </TeamMembersWrapper>
        </InnerContainerWrapper>
      </InnerContainer>
    </Container>
  )
}

export default Team
